/* eslint-disable react/self-closing-comp */
/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
/* eslint-disable react/no-unstable-nested-components */
// DEPENDENCIES
import React, { useState } from 'react';
import PropTypes from 'prop-types';
// ICONS
// import { IoLogoTiktok } from 'react-icons/io5';
// import {
//   RiFacebookFill,
//   RiInstagramLine,
//   RiTwitterFill,
//   RiLinkedinFill,
//   RiYoutubeLine,
// } from 'react-icons/ri';
import { RiInstagramLine, RiQuestionAnswerFill } from 'react-icons/ri';
import {
  FaFacebookF,
  FaLinkedinIn,
  FaPhoneAlt
} from 'react-icons/fa';
import { MdEmail, MdOutlineMail } from 'react-icons/md';
// import { IoCopyOutline } from 'react-icons/io5';
import { LuClipboardCopy } from 'react-icons/lu';
import { PiPhoneCallBold } from 'react-icons/pi';
import { TbMessage } from 'react-icons/tb';
// COMPONENTS
import { OverlayTrigger, Popover } from 'react-bootstrap';
// CUSTOM COMPONENTS
import CustomBlock from './CustomBlock';
import Button from './Button';
import Image from './Image';
import TextBlock from './TextBlock';
import InputBlock from './InputBlock';
import SocialIconSet from './SocialIconSet';
// ASSETS
import AppStoreBadge from '../assets/img/app-store-badge.svg';
import GooglePlayBadge from '../assets/img/google-play-badge.svg';

const FooterBlock = (props) => {
  const {
    history,
    to,
    phoneNumber,
    emailAddress,
    imageSource,
    backgroundColor,
    bottomFooterBackgroundColor,
    subheaderColor,
    subheaderClassName,
    companyName,
    copyrightTextColor,
    footerHeaderTextColor,
    footerItems,
    iconSize,
    iconAppearance,
    socialIconColor,
    socialIconBackgroundColor,
    appStoreLink,
    googlePlayLink,
    facebookLink,
    instagramLink,
    twitterLink,
    linkedInLink,
    youtubeLink,
    tiktokLink,
    // primaryActionOnClick,
    // secondaryActionOnClick,
    hasSocialMediaIconColor,
    hasEmailSignUp,
    hasContactBlock,
    cmsInfo
  } = props;

  const [isPhonePopoverOpen, setIsPhonePopoverOpen] = useState(false);
  const [isEmailPopoverOpen, setIsEmailPopoverOpen] = useState(false);

  const iconAppearanceOptions = {
    square: 'square',
    round: 'round',
    circle: 'circle',
  };

  // const SocialIconButton = (socialIconButtonProps) => {
  //   const {
  //     displayIf,
  //     buttonColor,
  //     buttonText,
  //     socialIcon,
  //     onClickAction,
  //     className,
  //     title
  //   } = socialIconButtonProps;
  //   return displayIf && (
  //     <button
  //       onClick={onClickAction}
  //       className={className}
  //       title={title}
  //       style={{ background: `${buttonColor}` }}
  //     >
  //       {socialIcon}
  //       {buttonText && <p className="white--clr fw-500">{buttonText}</p>}
  //     </button>
  //   );
  // };

  const openInNewTab = (link) => {
    window.open(link, '_blank');
  };

  const navigateToPage = (page) => {
    history.push(page);
  };

  const navigateToLink = (link) => {
    window.open(link, '_blank');
  };

  const sendEmail = (email) => {
    window.open(`mailto:${email}`, '_self');
  };

  const callPhoneNumber = (tel) => {
    window.open(`tel:${tel}`, '_self');
  };

  // const socialItemsFooter = [
  //   {
  //     platform: 'googleMap',
  //     displayIf: cmsInfo.googleMapLink !== null,
  //     title: `Google Maps | ${cmsInfo.googleMapLink}`,
  //     buttonColor: '#FAFAFA',
  //     onClickAction: () => openInNewTab(cmsInfo.googleMapLink),
  //     socialIcon: (
  //       <svg
  //         width="21"
  //         height="auto"
  //         viewBox="0 0 210 301"
  //         fill="none"
  //         xmlns="http://www.w3.org/2000/svg"
  //       >
  //         <path d="M57.7653 222.501C66.4418 233.558 74.3385 245.205 81.3983 257.358C87.4261 268.787 89.9389 276.537 94.3441 290.309C97.0457 297.917 99.4874 300.188 104.737 300.188C110.457 300.188 113.053 296.325 115.058 290.35C119.222 277.345 122.489 267.42 127.644 258.041C137.759 239.935 150.328 223.845 162.678 208.382C166.021 204.005 187.638 178.508 197.369 158.39C197.369 158.39 209.33 136.291 209.33 105.427C209.33 76.5567 197.56 56.514 197.56 56.514L57.7653 222.501Z" fill="#34A853" />
  //         <path d="M10.3759 154.675C21.5822 180.272 43.192 202.772 57.8127 222.519L135.47 130.529C135.47 130.529 124.53 144.837 104.684 144.837C82.5779 144.837 64.7193 127.185 64.7193 104.926C64.7193 89.6619 73.9024 79.1758 73.9024 79.1758L10.3759 154.675Z" fill="#FBBC04" />
  //         <path d="M136.338 4.9981C162.129 13.3139 184.205 30.7719 197.558 56.5155L135.494 130.476C135.494 130.476 144.677 119.8 144.677 104.631C144.677 81.8537 125.497 64.8379 104.778 64.8379C85.1847 64.8379 73.9318 79.1226 73.9318 79.1226L136.338 4.9981Z" fill="#4285F4" />
  //         <path d="M24.6018 37.6777C40.0065 19.2469 67.1127 0.267578 104.483 0.267578C122.614 0.267578 136.273 5.02752 136.273 5.02752L73.873 79.1635L24.6018 37.6777Z" fill="#1A73E8" />
  //         <path d="M10.3759 154.675C10.3759 154.675 0.0664062 134.486 0.0664062 105.238C0.0664062 77.5948 10.8125 53.4313 24.6018 37.6777L73.9081 79.17L10.3759 154.675Z" fill="#EA4335" />
  //       </svg>
  //     ),
  //   },
  //   {
  //     platform: 'instagram',
  //     displayIf: cmsInfo.instagramLink !== null,
  //     title: `Instagram | ${cmsInfo.instagramLink}`,
  //     buttonColor: 'radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%,#d6249f 60%,#285AEB 90%)',
  //     socialIcon: <RiInstagramLine className={socialIconColor} size="29" />,
  //     onClickAction: () => openInNewTab(cmsInfo.instagramLink)
  //   },
  //   {
  //     platform: 'linkedin',
  //     displayIf: cmsInfo.linkedInLink !== null,
  //     title: `LinkedIn | ${cmsInfo.linkedInLink}`,
  //     buttonColor: '#0966C2',
  //     socialIcon: <FaLinkedinIn className={socialIconColor} size={iconSize} />,
  //     onClickAction: () => openInNewTab(cmsInfo.linkedInLink),
  //   },
  //   {
  //     platform: 'facebook',
  //     displayIf: cmsInfo.facebookLink !== null,
  //     title: `Facebook | ${cmsInfo.facebookLink}`,
  //     buttonColor: 'linear-gradient(to top, #0062E0, #19AFFF)',
  //     socialIcon: <FaFacebookF className={socialIconColor} size={iconSize} />,
  //     onClickAction: () => openInNewTab(cmsInfo.facebookLink),
  //   },
  //   {
  //     platform: 'contactUs',
  //     displayIf: true,
  //     title: 'Contact Us',
  //     buttonColor: '#444444',
  //     socialIcon: <RiQuestionAnswerFill className={socialIconColor} size={iconSize} />,
  //     onClickAction: () => navigateToPage('/contact-sip-225'),
  //   },
  //   {
  //     platform: 'admin',
  //     displayIf: true,
  //     title: 'Admin Login',
  //     buttonColor: '#004E8C',
  //     socialIcon: <RiSettings3Fill className={socialIconColor} size={iconSize} />,
  //     onClickAction: () => navigateToPage('/cms/management/pages'),
  //   },
  // ];

  const handlePhonePopoverToggle = () => {
    setIsPhonePopoverOpen(!isPhonePopoverOpen);
  };

  const handleEmailPopoverToggle = () => {
    setIsEmailPopoverOpen(!isEmailPopoverOpen);
  };

  const phonePopover = (
    <Popover id="popover-phone" className="popover-body">
      <CustomBlock
        className="popover-btn"
        onClick={() => {
          navigator.clipboard.writeText(phoneNumber);
          setIsPhonePopoverOpen(false);
        }}
      >
        <LuClipboardCopy className="primary--clr" size="26" />
        <p className="size-md fw-500 primary--clr">Copy to Clipboard</p>
      </CustomBlock>

      <hr className="light-grey--clr mt-5 mb-5" style={{ height: '2px' }} />

      <CustomBlock
        className="popover-btn"
        onClick={() => {
          callPhoneNumber(phoneNumber);
          setIsPhonePopoverOpen(false);
        }}
      >
        <PiPhoneCallBold className="primary--clr" size="28" />
        <p className="size-md fw-500 primary--clr">Call LAMA</p>
      </CustomBlock>
    </Popover>
  );

  const emailPopover = (
    <Popover id="popover-email" className="popover-body">
      <CustomBlock
        className="popover-btn"
        onClick={() => {
          navigator.clipboard.writeText(emailAddress);
          setIsEmailPopoverOpen(false);
        }}
      >
        <LuClipboardCopy className="primary--clr" size="26" />
        <p className="size-md fw-500 primary--clr">Copy to Clipboard</p>
      </CustomBlock>

      <hr className="light-grey--clr mt-5 mb-5" style={{ height: '2px' }} />

      <CustomBlock
        className="popover-btn"
        onClick={() => {
          sendEmail(emailAddress);
          setIsEmailPopoverOpen(false);
        }}
      >
        <MdOutlineMail className="primary--clr" size="28" />
        <p className="size-md fw-500 primary--clr">Email LAMA</p>
      </CustomBlock>

      <hr className="light-grey--clr mt-5 mb-5" style={{ height: '2px' }} />

      <CustomBlock
        className="popover-btn"
        onClick={() => {
          history.push('/contact-louisiana-additive-manufacturing-association');
          setIsEmailPopoverOpen(false);
        }}
      >
        <TbMessage className="primary--clr" size="28" />
        <p className="size-md fw-500 primary--clr">Send a Message</p>
      </CustomBlock>
    </Popover>
  );

  return (
    <CustomBlock className={`footer-block ${backgroundColor}`}>
      <CustomBlock className="main-footer">
        <CustomBlock className="left-block">
          <Image
            to={to}
            source={`${process.env.REACT_APP_API_URL}Attachments/${imageSource}`}
          />
        </CustomBlock>

        <CustomBlock className="center-block">
          <TextBlock
            isUppercase
            isPadded
            type="subheader"
            text="Quick Links"
            // color={footerHeaderTextColor}
            color="lighter--clr"
          />

          <CustomBlock className="links">
            <ul>
              {
                footerItems.map((item) => (
                  <li>
                    <a
                      href="/"
                      onClick={(e) => {
                        e.preventDefault();
                        // eslint-disable-next-line no-unused-expressions
                        (item.to).includes('https' || 'http') ? navigateToLink(item.to) : history.push(item.to);
                      }}
                      className="light--clr fw-600"
                    >
                      {item.label}
                    </a>
                  </li>
                ))
              }
            </ul>
          </CustomBlock>
        </CustomBlock>

        <CustomBlock className="right-block">
          {
            hasEmailSignUp
            && (
              <CustomBlock className={`${appStoreLink || googlePlayLink !== '' ? 'mb-60' : ''}`}>
                <TextBlock
                  isUppercase
                  type="subheader"
                  text="Get the latest deals"
                  color={footerHeaderTextColor}
                />

                <InputBlock
                  placeholder="Enter your email"
                  inputRightButtonText="Sign Up"
                  inputRightButtonBackgroundColor={`lighter-blue--sbg ${iconAppearance === 'circle' ? 'circle' : ''}`}
                  inputRightButtonTextColor="info--clr"
                  backgroundColor="white--sbg"
                  className={`${iconAppearance === 'circle' ? 'circle' : ''}`}
                />
              </CustomBlock>
            )
          }

          {
            hasContactBlock && (!appStoreLink || !googlePlayLink)
            && (
              <CustomBlock className="contact-block">
                <TextBlock
                  isUppercase
                  type="subheader"
                  text="Contact Us"
                  color={footerHeaderTextColor}
                  className="size-mdlg pb-15"
                />

                {/* <CustomBlock className=""> */}
                <OverlayTrigger
                  trigger="click"
                  placement="top"
                  overlay={phonePopover}
                  show={isPhonePopoverOpen}
                  onToggle={handlePhonePopoverToggle}
                  rootClose
                >
                  <CustomBlock
                    className="flex-start footer-company-details mb-20"
                    onTouchStart={handlePhonePopoverToggle}
                  >
                    <FaPhoneAlt className="light--clr" size="24" />
                    <p className="size-md white--clr fw-500">{phoneNumber}</p>
                  </CustomBlock>
                </OverlayTrigger>

                <OverlayTrigger
                  trigger="click"
                  placement="top"
                  overlay={emailPopover}
                  show={isEmailPopoverOpen}
                  onToggle={handleEmailPopoverToggle}
                  rootClose
                >
                  <CustomBlock
                    className="flex-start footer-company-details mb-20"
                    onTouchStart={handleEmailPopoverToggle}
                  >
                    <MdEmail className="light--clr" size="28" />
                    <p className="size-md white--clr fw-500">{emailAddress}</p>
                  </CustomBlock>
                </OverlayTrigger>

                {/* <Button
                    text="Message Us"
                    className="white--bdr"
                    size="sm"
                    onClick={() => {
                      sendEmail(emailAddress);
                    }}
                  /> */}

                {/* <Button
                    text="Call Us"
                    className="secondary--bg"
                    size="sm"
                    onClick={() => {
                      callPhoneNumber(phoneNumber);
                    }}
                  /> */}
                {/* </CustomBlock> */}
              </CustomBlock>
            )
          }

          {/* {
            (appStoreLink || googlePlayLink)
            && (
              <CustomBlock className="app-download-block">
                <TextBlock
                  isPadded
                  isUppercase
                  type="subheader"
                  text="Download Our App"
                  color={subheaderColor}
                  className={subheaderClassName}
                />

                <CustomBlock className="app-download-badges">
                  {
                    appStoreLink
                    && (
                      <Image
                        source={AppStoreBadge}
                        onClick={() => navigateToLink(appStoreLink)}
                      />
                    )
                  }

                  {
                    googlePlayLink
                    && (
                      <Image
                        source={GooglePlayBadge}
                        onClick={() => navigateToLink(googlePlayLink)}
                      />
                    )
                  }
                </CustomBlock>
              </CustomBlock>
            )
          } */}

        </CustomBlock>
      </CustomBlock>

      <CustomBlock className="social-media-block">
        <CustomBlock className="social-icons">
          {/* {socialItemsFooter.map((item, index) => (
            <SocialIconButton
              displayIf={item.displayIf}
              buttonColor={item.buttonColor}
              buttonText={item.buttonText}
              socialIcon={item.socialIcon}
              onClickAction={item.onClickAction}
              className={`social-icon ${item.className || ''}`}
              title={item.title}
            />
          ))} */}
          <SocialIconSet
            cmsInfo={cmsInfo}
            history={history}
            location="footer"
          />
        </CustomBlock>
      </CustomBlock>

      {/* <CustomBlock className={`bottom-footer ${bottomFooterBackgroundColor}`}> */}
      <CustomBlock className="bottom-footer">
        <p className={`copyright ${copyrightTextColor}`}>{`Copyright © ${new Date().getFullYear()} ${companyName}. All Rights Reserved.`}</p>
        <button
          className={`copyright ml-5 ${copyrightTextColor}`}
          onClick={() => {
            navigateToLink('https://primtek.net/');
          }}
        >
          Developed by Primtek.
        </button>
      </CustomBlock>
    </CustomBlock>
  );
};

FooterBlock.propTypes = {
  // MAIN PROPS
  to: PropTypes.string,
  backgroundColor: PropTypes.string,
  bottomFooterBackgroundColor: PropTypes.string,
  imageSource: PropTypes.string,

  // CONTACT PROPS
  phoneNumber: PropTypes.string,
  emailAddress: PropTypes.string,

  // OPTIONAL PROPS

  // SUBHEADER PROPS
  subheaderColor: PropTypes.string,
  subheaderClassName: PropTypes.string,

  // APP STORE PROPS
  appStoreLink: PropTypes.string,
  googlePlayLink: PropTypes.string,

  // SOCIAL MEDIA PROPS
  iconSize: PropTypes.number,
  iconAppearance: PropTypes.string.isRequired,
  socialIconBackgroundColor: PropTypes.string,
  socialIconColor: PropTypes.string,
  facebookLink: PropTypes.string,
  instagramLink: PropTypes.string,
  twitterLink: PropTypes.string,
  linkedInLink: PropTypes.string,
  youtubeLink: PropTypes.string,
  tiktokLink: PropTypes.string,

  // FOOTER PROPS
  footerHeaderTextColor: PropTypes.string.isRequired,

  // BOTTOM FOOTER PROPS
  companyName: PropTypes.string.isRequired,
  copyrightTextColor: PropTypes.string,

  // FUNCTION PROPS
  // primaryActionOnClick: PropTypes.func,
  // secondaryActionOnClick: PropTypes.func,

  // BOOLEAN PROPS
  hasSocialMediaIconColor: PropTypes.bool,
  hasEmailSignUp: PropTypes.bool,
  hasContactBlock: PropTypes.bool,

  footerItems: PropTypes.array,
};

FooterBlock.defaultProps = {
  // MAIN PROPS
  to: '#',
  backgroundColor: 'white--sbg',
  bottomFooterBackgroundColor: 'lightest-grey--sbg',
  imageSource: '',

  // CONTACT PROPS
  phoneNumber: '',
  emailAddress: '',

  // OPTIONAL PROPS

  // SUBHEADER PROPS
  subheaderColor: 'secondary--clr',
  subheaderClassName: '',

  // APP STORE PROPS
  appStoreLink: '',
  googlePlayLink: '',

  // SOCIAL MEDIA PROPS
  iconSize: 27,
  socialIconColor: 'white--clr',
  socialIconBackgroundColor: 'secondary--sbg',
  facebookLink: '',
  instagramLink: '',
  twitterLink: '',
  linkedInLink: '',
  youtubeLink: '',
  tiktokLink: '',

  // BOTTOM FOOTER PROPS
  copyrightTextColor: 'primary--clr',

  // FUNCTION PROPS
  // primaryActionOnClick: null,
  // secondaryActionOnClick: null,

  // BOOLEAN PROPS
  hasSocialMediaIconColor: false,
  hasEmailSignUp: false,
  hasContactBlock: true,

  footerItems: [],
};

export default FooterBlock;