/* eslint-disable react/no-unknown-property */
/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-max-props-per-line */
/* eslint-disable max-len */
/* eslint-disable react/jsx-one-expression-per-line */
// DEPENDENCIES
import React, { useState, useEffect } from 'react';
import { Fade } from 'react-awesome-reveal';
// COMPONENTS
// import Slider from 'react-slick';
// CUSTOM COMPONENTS
import CustomBlock from './CustomBlock';
import Image from './Image';
import Overlay from './Overlay';
import Logo from './Logo';
// ASSETS
// import LogoAlt1 from '../assets/img/logo-alt-1.svg';
// import LogoAlt2 from '../assets/img/logo-alt-2.svg';
import LogoLight from '../assets/img/logo-light.svg';
import LogoDark from '../assets/img/logo.svg';
// import BannerImage from '../assets/img/banner-image_default.png';
// import IllustrationSquare from '../assets/img/illustration-square.svg';
import Video1 from '../assets/video/video-2.mp4';
import HeroBannerOverlay from '../assets/img/hero-banner-overlay.svg';
import BannerImageDefault from '../assets/img/banner-image_homepage-default-2.png';
// import IllustrationNodes from '../assets/img/illustration-nodes.png';
// HELPERS
import getSettingByKey from '../services/management/settingService';
import constants from '../constants/constants';

const VideoBackground = ({ src, children, playbackRate = 0.5 }) => {
  // Reference to the video element
  const videoRef = React.useRef(null);

  // Effect to apply the playback rate
  React.useEffect(() => {
    if (videoRef.current) {
      videoRef.current.playbackRate = playbackRate;
    }
  }, [playbackRate]);

  return (
    <div className="video-background">
      <video
        className="video-element"
        ref={videoRef}
        preload="auto"
        autoPlay="autoplay"
        loop="loop"
        muted
        playsInline
      >
        <source src={src} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      {children}
    </div>
  );
};

const DynamicTagline = ({ items, direction, duration }) => {
  const [currentIndustry, setCurrentIndustry] = useState(0);

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentIndustry((prevIndustry) => (prevIndustry + 1) % items.length);
    }, duration);

    // CLEANUP
    return () => clearInterval(timer);
  }, [items, duration]); // Dependencies ensure the interval updates if items or duration change

  return (
    <CustomBlock className="d-flex flex-column align-items-start gap-1">
      <CustomBlock className="d-flex align-items-center justify-content-center gap-3">
        <h1 className="dynamic-header white--clr fw-600">Shaping</h1>
        <Fade key={currentIndustry} direction={direction} triggerOnce={false}>
          <h1 className="dynamic-header fw-600">
            <span className="dynamic-header-input primary-lightest--clr fw-800">{items[currentIndustry]}</span>
            {/* <span className="dynamic-header-input secondary-lightest--clr fw-800">{items[currentIndustry]}</span> */}
          </h1>
        </Fade>
      </CustomBlock>
      <h1 className="dynamic-header white--clr fw-600">with Additive Manufacturing</h1>
    </CustomBlock>
  );
};

const CustomBannerInsert = (props) => {
  const { history, offsetValue } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [rotatingImagesLength, setRotatingImagesLength] = useState('');
  const industries = ['Aerospace', 'Agriculture', 'Healthcare', 'Automotive', 'Defense', 'Energy', 'Construction', 'Petrochemical'];

  useEffect(() => {
    if (!rotatingImagesLength) {
      const fetchData = async () => {
        setIsLoading(true);
        try {
          const res = await getSettingByKey('RotatingImagesLength');
          setRotatingImagesLength(res);
        } catch (ex) {
          // showAlert({ text: ex.message, state: 'error' });
        } finally {
          setIsLoading(false);
        }
      };

      fetchData();
    }
  }, []);

  return (
    <>
      {isLoading && <Overlay />}
      <CustomBlock
        className="custom-banner"
      // style={{
      //   backgroundImage: `url(${BannerImageDefault})`,
      //   backgroundPositionY: `${offsetValue * 0.25}px`,
      //   backgroundPositionX: '38%',
      //   backgroundRepeat: 'no-repeat',
      //   backgroundSize: 'cover',
      // }}
      >
        {/* <CustomBlock className="">
          <img src={BannerImageAsset1} className="" alt="" />
        </CustomBlock> */}

        <CustomBlock className="custom-banner__primary p-10">
          {/* LOGO */}
          {/* <Image
            source={LogoDark}
            // source={LogoLight}
            alt="Company Logo"
            containerClassName="image-container"
          /> */}
          <Logo
            width="160"
            // height="auto"
            // fillColor="secondary--clr"
            // fillColor="red"
            // color="#000"
            // className="primary-lightest--clr"
            // containerClassName="image-container"
            // className="lighter--clr m-auto"
            className="lighter--clr m-auto"
            style={{ filter: 'drop-shadow( 2px 2px 6px rgba(24, 24, 24, 0.55))' }}
          />

          <DynamicTagline items={industries} direction="right" duration={3000} />

          {/* DIVIDER */}
          {/* <CustomBlock>
            <Fade direction="left">
            <svg width="251" height="18" viewBox="0 0 251 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M111.201 10.0927H0.128906V8.3176H111.201V10.0927Z" fill="#3f4254" />
            <path d="M125.129 0.887695L116.868 9.20515L125.129 17.5226L133.39 9.20502L125.129 0.887695Z" fill="#3f4254" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M250.129 10.0927H139.057V8.3176H250.129V10.0927Z" fill="#3f4254" />
            </svg>
            </Fade>
          </CustomBlock> */}

          <CustomBlock className="mt-20">
            <Fade delay={300} duration={1500}>
              <CustomBlock className="button-style-1">
                <button className="learn-more" onClick={() => history.push('/about-louisiana-additive-manufacturing-association')}>
                  <span className="circle" aria-hidden="true">
                    <span className="icon arrow" />
                  </span>
                  <span className="button-text">Learn More</span>
                </button>
              </CustomBlock>
            </Fade>
          </CustomBlock>
        </CustomBlock>

        {/* <CustomBlock className="custom-banner__secondary">
          <Image
            source={LogoAlt2}
            alt="Company Logo"
            containerClassName="image-container"
            />
            <RotatingImageSlider
            length={rotatingImagesLength}
          />
        </CustomBlock> */}

        <CustomBlock className="custom-shape-divider-1">
          <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
            <path d="M892.25 114.72L0 0 0 120 1200 120 1200 0 892.25 114.72z" class="shape-fill" />
          </svg>
        </CustomBlock>

        <img src={HeroBannerOverlay} className="hero-banner-overlay" alt="" />
        <VideoBackground src={Video1} />
      </CustomBlock>
    </>
  );
};

export default CustomBannerInsert;