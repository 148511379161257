/* eslint-disable object-curly-newline */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-max-props-per-line */
/* eslint-disable no-use-before-define */
// DEPENDENCIES
import React from 'react';
import Banner from '../../../components/Banner';
// import { Fade } from 'react-awesome-reveal';
// COMPONENTS
import CustomBlock from '../../../components/CustomBlock';
import Button from '../../../components/Button';
// ASSETS
import BannerImage from '../../../assets/img/banner-image_default.png';

const DonationPage = () => (
  <>
    <Banner
      isDynamicBanner
      isTitleUppercase
      title="Donate"
      imageSource={BannerImage}
    />
    <CustomBlock className="main-content" style={{ height: '2200px', width: '100%', background: '#FFF' }}>
      {/* CONTENT SECTION - INTRO TO ABOUT */}
      <CustomBlock className="content-section flex-column">
        <CustomBlock>
          <h3 className="header size-xl fw-700 primary--clr pb-20">Support LAMA</h3>
          <p className="description mid-grey--clr size-md">
            The Louisiana Additive Manufacturing Association (LAMA) advances additive manufacturing technology in Louisiana. Your donations drive innovation, education, and growth.
          </p>
        </CustomBlock>

        <CustomBlock className="w-100 mt-20">
          <p className="description primary--clr fw-700 size-lg mb-10">
            Membership Opportunities
          </p>

          <CustomBlock
            style={{
              display: 'grid',
              gridTemplateColumns: 'repeat(auto-fit, minmax(200px, 1fr))',
              gap: '20px'
            }}
          >
            <p
              className="d-flex flex-column description mid-grey--clr size-md round"
              style={{
                boxShadow: 'rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px',
                padding: '15px'
              }}
            >
              <span className="primary-lighter--clr fw-600">Sponsors</span>
              <span>Priority access to event tickets and expo booths.</span>
            </p>
            <p
              className="d-flex flex-column description mid-grey--clr size-md round"
              style={{
                boxShadow: 'rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px',
                padding: '15px'
              }}
            >
              <span className="primary-lighter--clr fw-600">Company Membership</span>
              <span>$350 per year: Includes company name, logo, link to website (clickable logo), contact name, and email.</span>
            </p>
            <p
              className="d-flex flex-column description mid-grey--clr size-md round"
              style={{
                boxShadow: 'rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px',
                padding: '15px'
              }}
            >
              <span className="primary-lighter--clr fw-600">Individual Membership</span>
              <span>$120 per year: Includes name, email, and city.</span>
            </p>
          </CustomBlock>
        </CustomBlock>

      </CustomBlock>

      <iframe
        title="Donation form powered by Zeffy"
        style={{ width: '100%', height: '100%', border: 0 }}
        src="https://www.zeffy.com/embed/donation-form/d361f120-d3da-4b13-a7b1-447734a60b33"
        allowPaymentRequest
        allowTransparency="true"
      />
    </CustomBlock>
  </>
);

export default DonationPage;